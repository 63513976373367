export const FAST_TRACK_STORAGE_KEY = 'hin_fasttrack';
export const NON_OWNER_ROLES = ['Financial Advisor', '401(k) for my client’s business'];
export const CURRENT_EMPLOYEE_ROLES = [
  'Employee / Trying to Login',
  'Other',
  'Accessing my employee 401(k)',
];
export const NON_OWNER_REDIRECT_PATH = '/partners/';
export const CURRENT_EMPLOYEE_REDIRECT_URL = 'https://app.humaninterest.com/login';
export const NEW_USER_REDIRECT_PATH = '/lp/thank-you';
export const FAST_TRACK_COOKIE = 'hin_fasttrack';
export const STAGING_REDIRECT_URL =
  'https://provider-main.app-dev.inside.humaninterest.com/admin/onboarding/start';
export const PROD_REDIRECT_URL = 'https://app.humaninterest.com/admin/onboarding/start';
export const DEV_REDIRECT_URL = 'http://localhost:3000/admin/onboarding/start';
